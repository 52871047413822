export const VALID_TOKEN = "INVALID_TOKEN";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";
export const DISPLAY_VIEW = ["0", "1"];

export const SORT_MODE = ["created_at desc", "created_at asc"];

export const SORT_ALBUM = [
  "file_name asc",
  "file_name desc",
  "uploaded_at desc",
  "uploaded_at asc",
];

export const FILTER_PHOTO = {
  all: "",
  recommend: "is_recommended",
  like: "is_liked",
  comment: "is_commented",
};

export const DEFAULT_LANGUAGE = "en";
