import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Form, Input, Switch, Tooltip } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import { Edit2, Plus, Trash2, Upload as UploadIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { AbilityContext, PERMISSION } from "../../services/ability.service";
import {
  useCreateAlbumApi,
  useEditAlbumApi,
} from "../../services/apis/album.api";
import { useGetMeApi } from "../../services/apis/user.api";
import { setIsCreateSuccess } from "../../stores/reducers/album.reducer";
import { useRootSelector } from "../../stores/reducers/root";
import { TAlbumDetail } from "../../types/album.type";
import { focusValidation } from "../../utils/common";
import MessageModal from "../MessageModal";
import CheckLinkAccess from "./CheckLinkAccess";
import TagInput from "./TagInput";

const CreateEditAlbum = ({
  dfAlbum,
  onClick,
}: {
  dfAlbum?: TAlbumDetail;
  onClick: (data?: any) => void;
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userProfile = useGetMeApi();
  const { mutate: mutationCreate, isLoading: loadingCreate } =
    useCreateAlbumApi();
  const { mutate: mutationEdit, isLoading: loadingEdit } = useEditAlbumApi();

  const ability = useContext(AbilityContext);
  const isFaceFider = ability.can("can", PERMISSION.FACE_FIDER);
  const isDownloadImage = ability.can("can", PERMISSION.DOWNLOAD_IMAGE);
  const isCreateSuccess = useRootSelector(
    (state) => state.album.isCreateSuccess
  );

  const [uploadedFile, setUpLoadedFile] = useState<File>(undefined);
  const [validUploaded, setValidUploaded] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isCheckLinkAccess, setIsCheckLinkAccess] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleOpenFile = () => {
    if (!!inputRef.current) inputRef.current.click();
  };
  const handleChange = (event: any) => {
    if (event?.target.files) {
      setUpLoadedFile(event?.target.files[0]);
    }
  };

  const isCommentable = Form.useWatch("is_commentable", form);
  const isProtectedAlbum = Form.useWatch("is_protectable", form);
  const isLimitLike = Form.useWatch("is_limit_Like", form);

  const onFinish = (values: any) => {
    if (!isCheckLinkAccess) return;
    if (uploadedFile && uploadedFile.size / 1024 / 1024 > 40) return;
    let formData = new FormData();
    if (!!dfAlbum) formData.append("_method", "PUT");
    formData.append("customer_name", values?.customer_name);
    formData.append("name", values?.name);
    formData.append("folder_links[]", values?.folder_links1);
    if (uploadedFile) formData.append("image", uploadedFile);
    values?.folder_links?.forEach((item: any) => {
      !!item["folder_links"] &&
        formData.append("folder_links[]", item["folder_links"]);
    });
    formData.append("is_downloadable", `${+!!values?.is_downloadable}`);
    formData.append("is_commentable", `${+!!values?.is_commentable}`);
    if (isProtectedAlbum) {
      formData.append("password", values?.password);
    }
    formData.append("allow_face_finder", `${+!!values?.allow_face_finder}`);
    if (isCommentable) {
      formData.append("comment_password", values?.comment_password);
    }
    if (isLimitLike) {
      formData.append("max_file_likes", `${+values?.max_file_likes}`);
    }

    values?.tags?.forEach((tag, index) => {
      formData.append(`tags[${index}][id]`, tag.id ?? "");
      formData.append(`tags[${index}][name]`, tag.name);
    });

    (!!dfAlbum ? mutationEdit : mutationCreate)(
      !!dfAlbum
        ? {
            id: dfAlbum.id,
            dataQuery: formData,
          }
        : formData,
      {
        onSuccess(data) {
          if (data?.data?.success) {
            onClick && onClick(data);
            setIsModalOpen(true);
            userProfile.refetch();
            setUpLoadedFile(undefined);
            !dfAlbum && form.resetFields();
          }
        },
        onError() {
          !!dfAlbum
            ? setError(t("formEditAlbum.editError.error"))
            : setError(t("formCreateAlbum.createError.error"));
        },
      }
    );
  };
  const handleClick = () => {
    if (uploadedFile && uploadedFile.size / 1024 / 1024 > 40) {
      const temp = t("formCreateAlbum.checkValidImage.size");
      setValidUploaded(temp);
    }
  };

  const handleCheckLinkAccess = (value: boolean) => {
    setIsCheckLinkAccess(value);
  };
  useEffect(() => {
    setValidUploaded("");
  }, [uploadedFile]);

  useEffect(() => {
    if (!!dfAlbum) {
      const data = {
        is_downloadable: !!dfAlbum?.is_downloadable,
        is_commentable: !!dfAlbum?.is_commentable,
        allow_face_finder: !!dfAlbum?.allow_face_finder,
        is_protectable: !!dfAlbum?.password,
        name: dfAlbum?.name,
        customer_name: dfAlbum?.customer_name,
        is_limit_Like: !!dfAlbum?.max_file_likes,
        max_file_likes: dfAlbum?.max_file_likes,
        folder_links1: dfAlbum?.folders[0]?.link,
        // folder_links: folder_links.map((item) => item.link),
      };
      form.setFieldsValue(data);
    } else {
      form.setFieldsValue({
        is_downloadable: false,
        is_commentable: false,
        allow_face_finder: false,
        is_protectable: false,
        is_limit_Like: false,
      });
    }
  }, [dfAlbum, form]);

  return (
    <>
      <Form
        form={form}
        name={`${!!dfAlbum ? "edit" : "create"}-album-form`}
        autoComplete="off"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={(errorInfo) => {
          focusValidation(
            errorInfo,
            `${!!dfAlbum ? "edit" : "create"}-album-form`
          );
        }}
        colon={false}
      >
        <div className="relative mb-4">
          <CheckLinkAccess
            name="folder_links1"
            label={t("formCreateAlbum.titleLinkGgDive")}
            tooltip={t("formCreateAlbum.tooltipLinkGgDive")}
            autoFocus={true}
            onCheckLinkAccess={handleCheckLinkAccess}
            required
          />
          <span className="text-xs text-slate-500">
            {t("formCreateAlbum.noteLink")}
          </span>
          <Form.List
            name="folder_links"
            initialValue={structuredClone(dfAlbum?.folders)
              ?.slice(1)
              .map((item) => item.link)}
          >
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key} className="flex">
                    <CheckLinkAccess
                      name={[field.name, `folder_links`]}
                      className="mt-3"
                      onCheckLinkAccess={handleCheckLinkAccess}
                      defaultValue={form.getFieldValue("folder_links")[index]}
                    />
                    <Trash2
                      className="dynamic-delete-button ml-2 w-5 text-primary cursor-pointer mt-[26px]"
                      onClick={() => remove(field.name)}
                    />
                  </div>
                ))}
                <div
                  onClick={() => {
                    if (fields.length < 4) add();
                  }}
                  className={`bg-secondary text-white border-0 absolute top-10 -right-1 flex items-center justify-center w-7 h-7 rounded-md ${
                    fields.length === 4
                      ? "cursor-default opacity-50"
                      : "cursor-pointer"
                  }`}
                >
                  <Plus className="w-4" />
                </div>
              </>
            )}
          </Form.List>
        </div>
        <Form.Item
          name="name"
          label={t("formCreateAlbum.titleNameAlbum")}
          rules={[
            {
              required: true,
              message: (
                <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                  {t("formCreateAlbum.requiredNameAlbum")}
                </span>
              ),
            },
          ]}
        >
          <Input
            placeholder={t("formCreateAlbum.placeholderNameAlbum")}
            className="py-2 px-3"
          />
        </Form.Item>
        <Form.Item
          name="customer_name"
          label={t("formCreateAlbum.titleNameUser")}
          rules={[
            {
              required: true,
              message: (
                <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                  {t("formCreateAlbum.requiredNameUser")}
                </span>
              ),
            },
          ]}
        >
          <Input
            placeholder={t("formCreateAlbum.placeholderNameUser")}
            className="py-2 px-3"
          />
        </Form.Item>
        <Form.Item
          label={
            <>
              Tags
              <span className="ml-1 text-xs text-slate-500">
                ({t("Max :number Tag", { number: 5 })})
              </span>
            </>
          }
          name="tags"
          initialValue={dfAlbum?.tags ?? []}
          rules={[]}
        >
          <TagInput max={5} />
        </Form.Item>
        <div className="relative flex items-center h-36 cursor-pointer rounded-lg transition-all mb-6">
          <div className="w-1/2 relative">
            {t("formCreateAlbum.titlePicture")}
          </div>
          <input
            type="file"
            ref={inputRef}
            accept="image/gif, image/jpeg, image/png, image/jpg, image/webp"
            className="!hidden"
            onChange={handleChange}
          />
          <div className="relative w-full h-full">
            {uploadedFile ? (
              <div className="relative w-full h-full">
                <div
                  onClick={() => {
                    setUpLoadedFile(undefined);
                  }}
                  className="absolute top-1 right-1 flex justify-center items-center w-7 h-7 rounded-full bg-gray-200/80 text-slate-700"
                >
                  <Trash2 className="w-4" />
                </div>
                <img
                  src={URL.createObjectURL(uploadedFile)}
                  alt="uploaded"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ) : (
              <div
                onClick={handleOpenFile}
                className="w-full h-full flex justify-center items-center border border-dashed text-gray-600 border-gray-400 hover:border-secondary hover:bg-gray-50 rounded-lg"
              >
                {dfAlbum?.thumbnail_url ? (
                  <>
                    <div className="absolute top-1 right-1 flex justify-center items-center w-7 h-7 rounded-full bg-gray-200/80 text-slate-700">
                      <Edit2 className="w-4" />
                    </div>
                    <img
                      src={dfAlbum?.thumbnail_url}
                      alt="thumbnail_url-default"
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </>
                ) : (
                  <UploadIcon className="w-5" />
                )}
              </div>
            )}
            <span className="absolute -bottom-[1.1rem] left-0 text-xs text-primary">
              {validUploaded}
            </span>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-center sm:gap-2 relative sm:h-12">
          <div className="flex justify-between sm:justify-start items-center w-full sm:w-auto">
            <span className="block sm:w-56 text-left w-full">
              {t("formCreateAlbum.allowComment")}
            </span>
            <Form.Item
              name="is_commentable"
              layout="horizontal"
              className="mb-0"
            >
              <Switch />
            </Form.Item>
          </div>
          {isCommentable && (
            <Form.Item
              name="comment_password"
              className="relative flex-1 w-full mb-2 sm:mb-0"
              rules={[
                {
                  required: isCommentable,
                  message: (
                    <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                      {t("formCreateAlbum.requirePass")}
                    </span>
                  ),
                },
                {
                  min: 6,
                  message: (
                    <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                      {t("formLogin.minPassword")}
                    </span>
                  ),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
          )}
        </div>
        <div className="hidden justify-between sm:justify-start items-center w-full sm:w-auto sm:py-1">
          <span
            className={`block sm:w-56 text-left w-full ${
              !isFaceFider ? "text-gray-500" : ""
            }`}
          >
            {t("formCreateAlbum.allowFace")}
            {!isFaceFider && (
              <Tooltip
                title={t("formCreateAlbum.recommended")}
                className="ml-1"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            )}
          </span>
          <Form.Item
            name="allow_face_finder"
            layout="horizontal"
            className="mb-0"
          >
            <Switch disabled={!isFaceFider} />
          </Form.Item>
        </div>
        <div className="flex flex-col sm:flex-row items-center sm:gap-2 relative sm:h-12">
          <div className="flex justify-between sm:justify-start items-center w-full sm:w-auto">
            <span className="block sm:w-56 text-left w-full">
              {t("formCreateAlbum.protectedAlbum")}
            </span>
            <Form.Item
              name="is_protectable"
              layout="horizontal"
              className="mb-0"
            >
              <Switch />
            </Form.Item>
          </div>
          {isProtectedAlbum && (
            <Form.Item
              name="password"
              className="relative flex-1 w-full mb-2 sm:mb-0"
              rules={[
                {
                  required: isProtectedAlbum,
                  message: (
                    <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                      {t("formCreateAlbum.requirePass")}
                    </span>
                  ),
                },
                {
                  min: 6,
                  message: (
                    <span className="text-xs sm:absolute relative mb-1 sm:mb-0 block">
                      {t("formLogin.minPassword")}
                    </span>
                  ),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
          )}
        </div>
        <div className="flex justify-between sm:justify-start items-center w-full sm:w-auto sm:py-1">
          <span
            className={`block sm:w-56 text-left w-full ${
              !isDownloadImage ? "text-gray-500" : ""
            }`}
          >
            {t("formCreateAlbum.allowDownload")}
            {!isDownloadImage && (
              <Tooltip
                title={t("formCreateAlbum.recommended")}
                className="ml-1"
              >
                <QuestionCircleOutlined />
              </Tooltip>
            )}
          </span>
          <Form.Item
            name="is_downloadable"
            layout="horizontal"
            className="mb-0"
          >
            <Switch disabled={!isDownloadImage} />
          </Form.Item>
        </div>

        <div className="flex flex-col sm:flex-row items-center sm:gap-2 relative sm:h-12">
          <div className="flex justify-between sm:justify-start items-center w-full sm:w-auto sm:py-1">
            <span className="block sm:w-56 text-left w-full">
              {t("formCreateAlbum.limitedNumber")}
            </span>
            <Form.Item
              name="is_limit_Like"
              layout="horizontal"
              className="mb-0"
            >
              <Switch />
            </Form.Item>
          </div>
          {isLimitLike && (
            <Form.Item
              initialValue={10}
              name="max_file_likes"
              className="relative flex-1 w-full mb-2 sm:mb-0"
            >
              <Input type="number" min={1} />
            </Form.Item>
          )}
        </div>
        <div className="flex gap-2 items-center justify-end mt-4">
          <Button
            type="default"
            className="transition-all text-gray-900"
            onClick={() => {
              onClick && onClick();
            }}
          >
            {t("formCreateAlbum.btnCancel")}
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            className="transition-all"
            loading={loadingCreate || loadingEdit}
            disabled={loadingCreate || loadingEdit}
            onMouseDown={() => handleClick()}
          >
            {t(`${!!dfAlbum ? "formEditAlbum" : "formCreateAlbum"}.btnOk`)}
          </Button>
        </div>
      </Form>
      <MessageModal
        isVisible={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          !dfAlbum && dispatch(setIsCreateSuccess(!isCreateSuccess));
        }}
        title={t(
          `${
            !!dfAlbum
              ? "formEditAlbum.editSuccess"
              : "formCreateAlbum.createSuccess"
          }.title`
        )}
        subTitle={t(
          `${
            !!dfAlbum
              ? "formEditAlbum.editSuccess"
              : "formCreateAlbum.createSuccess"
          }.subTitle`
        )}
        type="success"
        btnText={t("formCreateAlbum.createSuccess.btn")}
      />

      <MessageModal
        isVisible={!!error}
        onClose={() => {
          setError(null);
        }}
        title={t(
          `${
            !!dfAlbum
              ? "formEditAlbum.editError"
              : "formCreateAlbum.createError"
          }.title`
        )}
        subTitle={error}
        type="error"
        btnText={t("formCreateAlbum.createError.btn")}
      />
    </>
  );
};

export default CreateEditAlbum;
