import { Divider, Modal, Popover, Radio, Skeleton, Spin, Tooltip } from "antd";
import dayjs from "dayjs";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Download,
  List,
  RefreshCcw,
  Share2,
  Smile,
  Tool,
  Trash2,
} from "react-feather";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import { useQueryClient } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import AlbumGallery from "../../components/AlbumGallery";
import CreateEditAlbum from "../../components/Albums/CreateEditAlbum";
import DownloadAlbum from "../../components/Albums/DownloadAlbum";
import FilterAlbum from "../../components/Albums/FilterAlbum";
import ShareAlbum from "../../components/Albums/ShareAlbum";
import FolderAlbum from "../../components/FolderAlbum";
import MessageModal from "../../components/MessageModal";
import MetaTags from "../../components/MetaTags";
import { SORT_ALBUM } from "../../constants/commons";
import {
  fetchAlbumSync,
  fetchAllImages,
  useGetAlbumApi,
} from "../../services/apis/album.api";
import { TAlbumDetail } from "../../types/album.type";
import {
  checkLabeledImages,
  loadLabeledImages,
  loadModels,
} from "../../utils/faceRecognition";
import BannerAlbum from "../../components/Albums/BannerAlbum";

const Album = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryClient = useQueryClient();
  const isCheckPath = location.pathname.split("/")[1].includes("dashboard");
  const { ref, inView } = useInView();
  const [isOpenEditAlbum, setIsOpenEditAlbum] = useState<boolean>(false);
  const [isModalOpenDown, setIsModalOpenDown] = useState<boolean>(false);
  const [isModalOpenShare, setIsModalOpenShare] = useState<boolean>(false);
  const [sortMode, setSortMode] = useState<string>(SORT_ALBUM[0]);
  const [isFilter, setIsFilter] = useState(null);
  // const [facefinder, setFacefinder] = useState<boolean>(false);
  const [faceDetecting, setFaceDetecting] = useState<boolean>(false);
  const [foldersId, setFoldersId] = useState<number[]>(undefined);
  const [isLoadingReload, setIsLoadingReload] = useState<boolean>(false);
  const [isModalOpenFace, setIsModalOpenFace] = useState<
    "confirm" | "notfound"
  >(undefined);
  const inputRefFace = useRef<HTMLInputElement>(null);
  const [matchedImages, setMatchedImages] = useState<string[]>(undefined);

  const { albumId } = useParams();
  const {
    data: albumDetail,
    isLoading: loadingAlbum,
    refetch: refetchAlbum,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetAlbumApi({
    filterParams: { ...isFilter, in: matchedImages },
    perPage: 12,
    orderBy: !!sortMode ? sortMode : undefined,
    foldersId: foldersId,
  });
  const albumResponse = useMemo(() => {
    return albumDetail?.pages.reduce((acc, page) => {
      return [...acc, ...page?.data?.data?.files?.data];
    }, []);
  }, [albumDetail]);
  const albumDetailCache: TAlbumDetail =
    albumDetail?.pages[albumDetail.pages?.length === 1 ? 0 : 1]?.data?.data ||
    queryClient.getQueryData([`album_detail${albumId}`]);

  const onChangeSortMode = (e: any) => {
    setSortMode(e.target.value);
  };
  const contentSort = (
    <>
      <Radio.Group
        name="sort"
        className="flex flex-col gap-2"
        onChange={onChangeSortMode}
        value={sortMode}
      >
        {SORT_ALBUM?.map((mode, index) => (
          <Radio value={mode} key={mode}>
            {t(`albumDetail.sort.value${index + 1}`)}
          </Radio>
        ))}
      </Radio.Group>
      <Divider className="my-2" />
      <button
        onClick={() => {
          setSortMode("");
        }}
        disabled={!sortMode}
        className={`flex gap-2 items-center px-2 py-1 hover:bg-gray-200/50 rounded-lg w-full ${
          !!sortMode
            ? "cursor-pointer"
            : "bg-gray-200/50 opacity-60 cursor-not-allowed"
        }`}
      >
        <Trash2 className="w-4" />
        {t("albumDetail.sort.deleteSort")}
      </button>
    </>
  );

  // const handleUploadAndCheckImages = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   if (event.target.files && event.target.files[0]) {
  //     setFacefinder(true);
  //     setFaceDetecting(true);
  //     await loadModels();
  //     const file = event.target.files[0];
  //     const labeledFaceDescriptor = await loadLabeledImages(file);
  //     if (labeledFaceDescriptor.descriptors.length === 0) {
  //       setIsModalOpenFace("notfound");
  //       setFaceDetecting(false);
  //       setFacefinder(false);
  //       return;
  //     }

  //     const response = await fetchAllImages(albumId);

  //     const matchedUrls = await checkLabeledImages(
  //       response.data.data.files.data || [],
  //       labeledFaceDescriptor
  //     );
  //     if (matchedUrls.length > 0) {
  //       setMatchedImages(matchedUrls);
  //     } else {
  //       setIsModalOpenFace("notfound");
  //     }

  //     setFaceDetecting(false);
  //     event.target.value = "";
  //   }
  // };

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  // useEffect(() => {
  //   if (isLoadingReload) {
  //     if (!!foldersId && foldersId.length !== 0) setFoldersId([]);
  //     if (sortMode !== SORT_ALBUM[0]) setSortMode(SORT_ALBUM[0]);
  //     if (!!matchedImages) setMatchedImages(undefined);
  //     if (!!isFilter) setCheckedList(defaultCheckedList);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isLoadingReload]);

  return (
    <div className={`flex flex-col text-gray-900`}>
      {isCheckPath && (
        <BannerAlbum
          isLoading={!albumDetailCache?.id}
          data={{
            customer_name: albumDetailCache?.customer_name,
            name: albumDetailCache?.name,
            thumbnail_url: albumDetailCache?.thumbnail_url,
          }}
        />
      )}
      {!albumDetailCache?.id ? (
        <div className="w-full overflow-hidden mt-4">
          <Skeleton active />
        </div>
      ) : (
        <>
          <div className="flex sm:flex-row flex-col justify-between items-center py-2 lg:py-4 border-b gap-1">
            <FilterAlbum
              setIsFilter={(values) => {
                setIsFilter(values);
              }}
              counts={{
                all: albumDetailCache?.file_count,
                comment: albumDetailCache?.comment_count,
                like: albumDetailCache?.like_count,
                recommend: albumDetailCache?.recommend_count,
              }}
            />
            <div className="flex sm:space-x-2 space-x-1 text-sm items-center flex-wrap sm:flex-nowrap">
              {/* <button
                disabled={!albumDetailCache?.allow_face_finder}
                className={`py-1 px-3 flex gap-1 items-center transition-all rounded-3xl ${
                  facefinder
                    ? "text-secondary bg-[#0bcdac26] hover:bg-[#0bcdac26]"
                    : "hover:bg-gray-200/50"
                } ${
                  !albumDetailCache?.allow_face_finder
                    ? "bg-gray-200/50 opacity-60 cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                onClick={() => {
                  if (facefinder) {
                    setFacefinder(false);
                    setMatchedImages([]);
                  } else setIsModalOpenFace("confirm");
                }}
              >
                <Smile className="w-4" />
                <input
                  id="upload"
                  ref={inputRefFace}
                  type="file"
                  onChange={handleUploadAndCheckImages}
                  accept="image/*"
                  disabled={!albumDetailCache?.allow_face_finder}
                  style={{ display: "none" }}
                />
              </button> */}
              <Popover content={contentSort} trigger="click">
                <div
                  className={`py-1 px-3 flex gap-1 items-center cursor-pointer transition-all rounded-3xl ${
                    !!sortMode
                      ? "text-secondary bg-[#0bcdac26] hover:bg-[#0bcdac26]"
                      : "hover:bg-gray-200/50"
                  }`}
                >
                  <List className="w-4" />
                </div>
              </Popover>
              <Tooltip
                title={
                  t("albumDetail.updated") +
                  " " +
                  dayjs(albumDetailCache.updated_at)?.format(
                    "DD/MM/YYYY h:mm A"
                  )
                }
              >
                <button
                  className={`py-1 px-3 flex gap-1 items-center cursor-pointer transition-all rounded-3xl ${
                    isLoadingReload
                      ? "text-secondary bg-[#0bcdac26] hover:bg-[#0bcdac26]"
                      : "hover:bg-gray-200/50"
                  }`}
                  onClick={async () => {
                    setIsLoadingReload(true);
                    await fetchAlbumSync(albumId);
                    await refetchAlbum();
                    setIsLoadingReload(false);
                  }}
                >
                  <RefreshCcw
                    className={`w-4 ${isLoadingReload ? "animate-spin" : ""}`}
                  />
                </button>
              </Tooltip>
              <button
                disabled={
                  !isCheckPath &&
                  (!albumDetailCache?.is_downloadable ||
                    (albumDetailCache?.is_downloadable &&
                      albumDetailCache?.file_count === 0))
                }
                className={`${
                  !isCheckPath &&
                  (!albumDetailCache?.is_downloadable ||
                    (albumDetailCache?.is_downloadable &&
                      albumDetailCache?.file_count === 0))
                    ? "bg-gray-200/50 opacity-60 cursor-not-allowed"
                    : "cursor-pointer"
                } py-1 px-3 flex gap-1 items-center transition-all hover:bg-gray-200/50 rounded-3xl`}
                onClick={() => {
                  setIsModalOpenDown(true);
                }}
              >
                <Download className="w-4" />
              </button>
              <div
                onClick={() => {
                  setIsModalOpenShare(true);
                }}
                className="py-1 px-3 flex gap-1 items-center cursor-pointer transition-all hover:bg-gray-200/50 rounded-3xl"
              >
                <Share2 className="w-4" />
              </div>
              {isCheckPath && (
                <div
                  onClick={() => {
                    setIsOpenEditAlbum(true);
                  }}
                  className="cursor-pointer px-1 text-primary hover:opacity-85 transition-all flex items-center gap-1"
                >
                  <Tool className="w-4" />
                  {t("albumDetail.custom")}
                </div>
              )}
            </div>
          </div>

          {albumDetailCache?.folders?.length > 0 && (
            <div className="flex flex-col">
              <FolderAlbum
                folders={albumDetailCache?.folders}
                onClick={(data) => {
                  setFoldersId(data);
                }}
                loadingAlbum={loadingAlbum}
              />
            </div>
          )}
        </>
      )}

      <div className="py-4">
        {loadingAlbum || faceDetecting ? (
          <div className="w-full overflow-hidden">
            <Skeleton active />
          </div>
        ) : (
          <AlbumGallery
            images={albumResponse || []}
            fetchNextPage={fetchNextPage}
            albumDetail={albumDetailCache}
            isCheckPath={isCheckPath}
            isCommentPassword={
              albumDetail?.pages[0]?.data?.data?.comment_password
            }
            isDownloadable={albumDetail?.pages[0]?.data?.data?.is_downloadable}
            maxFileLikes={albumDetail?.pages[0]?.data?.data?.max_file_likes}
          />
        )}
        <div ref={ref}>
          {isFetchingNextPage ? (
            <div className="py-6 w-full flex justify-center fixed bottom-0 left-1/2 -translate-x-1/2 bg-gradient-to-t from-white to-transparent">
              <Spin size="large" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("dashboard.albums.action.download.modalDownload.title")}
          </div>
        }
        open={isModalOpenDown}
        onCancel={() => {
          setIsModalOpenDown(false);
        }}
        footer={null}
      >
        <DownloadAlbum
          id={albumDetail?.pages[0]?.data?.data?.id}
          totalPhotos={albumDetail?.pages[0]?.data?.data?.file_count}
        />
      </Modal>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("dashboard.albums.action.download.modalShare.title")}
          </div>
        }
        open={isModalOpenShare}
        onCancel={() => {
          setIsModalOpenShare(false);
        }}
        footer={null}
      >
        <ShareAlbum albumId={albumDetailCache?.id} />
      </Modal>
      <Modal
        title={
          <div className="text-primary font-semibold text-3xl">
            {t("formEditAlbum.title")}
          </div>
        }
        open={isOpenEditAlbum}
        onCancel={() => {
          setIsOpenEditAlbum(false);
        }}
        footer={null}
      >
        <CreateEditAlbum
          dfAlbum={albumDetailCache}
          onClick={(data) => {
            setIsOpenEditAlbum(false);
            if (!!data) refetchAlbum();
          }}
        />
      </Modal>
      <MessageModal
        isVisible={isModalOpenFace === "notfound"}
        onClose={() => {
          setIsModalOpenFace(undefined);
        }}
        title={t("albumDetail.face.title")}
        subTitle={t("albumDetail.face.faceFailed")}
        type="error"
        btnText={t("formCreateAlbum.createSuccess.btn")}
      />
      <MessageModal
        isVisible={isModalOpenFace === "confirm"}
        onClose={() => {
          setIsModalOpenFace(undefined);
        }}
        title={t("albumDetail.face.titleConfirm")}
        subTitle={t("albumDetail.face.subTitleConfirm")}
        type="confirm"
        btnText={
          <div
            className="cursor-pointer"
            onClick={() => {
              setIsModalOpenFace(undefined);
              if (inputRefFace) inputRefFace?.current?.click();
            }}
          >
            {t("albumDetail.face.searchBtn")}
          </div>
        }
      />
      <MetaTags
        title={albumDetailCache?.name}
        image={albumDetailCache?.thumbnail_url || ""}
        name="Shotpik"
      />
    </div>
  );
};

export default Album;
