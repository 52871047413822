import React, { useEffect, useRef } from 'react';

const HTMLElementWithEvent = ({ element }) => {
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current && element instanceof Element) {
      containerRef.current.replaceChildren(element);
    }
  }, [element]);

  return <span ref={containerRef}></span>;
};

export default HTMLElementWithEvent;
