import { Button, Modal } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { Check, ChevronsRight, X } from "react-feather";
import { useTranslation } from "react-i18next";
import { TbAlertTriangle } from "react-icons/tb";
import ggdrive from "../../assets/images/google-drive.svg";
import paneldarkroom from "../../assets/images/paneldarkroom.png";
import ExtendPackage from "../../components/PackageManagement/ExtendPackage";
import UpgradePackage from "../../components/PackageManagement/UpgradePackage";
import UpdatePhone from "../../components/Profile/UpdatePhone";
import ContactBlock from "../../components/ServicePackages/ContactBlock";
import { useServicePackages } from "../../constants/servicePackages";
import { useRootSelector } from "../../stores/reducers/root";
import { TMembershipPlanPrice } from "../../types/membership.type";
import ModalPayment from "../ServicePackages/ModalPayment";

const PackageManagement = () => {
  const { t } = useTranslation();
  const user = useRootSelector((state) => state.user.userProfile);
  const [isModalOpen, setIsModalOpen] = useState<
    | "upgrade"
    | "extend"
    | "phoneupgrade"
    | "phoneextend"
    | { buy: TMembershipPlanPrice }
  >(undefined);
  const packages = useServicePackages();
  const currentPackage =
    packages?.find((service) => service?.key === user?.membership_plan?.name) ||
    packages[0];
  const remainDate = dayjs(user?.subscription?.end_date)?.diff(
    dayjs(user?.subscription?.start_date),
    "day"
  );

  return (
    <>
      <div className="py-8 flex flex-col items-center">
        <span className="text-3xl font-bold text-center mb-10">
          {t("managerPackage.title")}
        </span>
        {currentPackage?.key !== "Free" && remainDate < 30 ? (
          <div className="flex gap-2 md:flex-row flex-col bg-[#f6921a] text-white py-4 md:py-2 px-2 md:px-4 rounded-md items-center mb-6 font-light">
            <TbAlertTriangle />
            <span className="text-center md:text-left">
              {t("managerPackage.note", {
                date: dayjs(user?.subscription?.end_date).format("DD/MM/YYYY"),
              })}
            </span>
            <div className='relative before:md:content-["|"] before:md:mx-3'>
              <span
                onClick={() => {
                  if (!!user.phone_number) setIsModalOpen("extend");
                  else setIsModalOpen("phoneextend");
                }}
                className="border rounded md:border-0 md:rounded-none py-2 px-3 hover:opacity-80 cursor-pointer font-medium text-nowrap"
              >
                {t("managerPackage.btn")}
              </span>
            </div>
          </div>
        ) : null}

        <div className="bg-gray-100 text-black pt-9 pb-12 px-10 rounded-lg flex flex-col w-full">
          <span className="text-center md:text-left">
            {t("managerPackage.package")}
          </span>
          <div className="text-3xl font-semibold mt-1 mb-5 text-center md:text-left">
            {currentPackage?.key}
          </div>
          <div
            className={`flex justify-between flex-col md:flex-row gap-y-4 md:gap-0 ${
              currentPackage?.key === "Free" ? "gap-4" : ""
            }`}
          >
            <div
              className={`flex items-center gap-4 sm:flex-row flex-col ${
                currentPackage?.key === "Free"
                  ? "justify-center"
                  : "justify-between"
              }`}
            >
              <div>
                <span className="font-semibold text-lg">
                  {t("managerPackage.feature") + ":"}
                </span>
                {Object.entries(currentPackage?.benefits)?.map(
                  ([key, benefit]) => (
                    <div key={key} className="flex items-center gap-2">
                      {benefit?.active ? (
                        <Check className="w-4 text-secondary" />
                      ) : (
                        <X className="w-4 text-primary" />
                      )}
                      <span
                        className={`${
                          !benefit?.active ? "text-gray-500" : ""
                        } flex-1`}
                      >
                        {benefit?.title}
                      </span>
                    </div>
                  )
                )}
              </div>
              {currentPackage?.key === "Free" ? null : (
                <div className="md:hidden flex relative text-center">
                  <div className="flex flex-col">
                    <span className="font-semibold">
                      {t("managerPackage.startDate") + ":"}
                    </span>
                    <span className="text-xl font-bold mt-2">
                      {dayjs(user?.subscription?.start_date).format(
                        "DD/MM/YYYY"
                      ) || "---"}
                    </span>
                    <span className="font-semibold mt-6">
                      {t("managerPackage.endDate") + ":"}
                    </span>
                    <span className="text-xl font-bold mt-2">
                      {dayjs(user?.subscription?.end_date).format(
                        "DD/MM/YYYY"
                      ) || "---"}
                    </span>
                    <span className="text-sm mt-2">
                      {t("managerPackage.expire", {
                        date: dayjs(user?.subscription?.end_date)?.diff(
                          dayjs(user?.subscription?.start_date),
                          "day"
                        ),
                      })}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {currentPackage?.key === "Free" ? null : (
              <div className="hidden md:flex relative before:w-[2px] before:bg-gray-300 before:lg:mx-5 before:mx-10 before:h-full after:w-[2px] after:bg-gray-300 after:mx-10 after:lg:mx-5 after:h-full">
                <div className="flex flex-col">
                  <span className="font-semibold text-lg">
                    {t("managerPackage.startDate") + ":"}
                  </span>
                  <span className="text-3xl font-bold mt-2">
                    {dayjs(user?.subscription?.start_date).format(
                      "DD/MM/YYYY"
                    ) || "---"}
                  </span>
                  <span className="font-semibold text-lg mt-12">
                    {t("managerPackage.endDate") + ":"}
                  </span>
                  <span className="text-3xl font-bold mt-2">
                    {dayjs(user?.subscription?.end_date).format("DD/MM/YYYY") ||
                      "---"}
                  </span>
                  <span className="text-sm mt-2">
                    {t("managerPackage.expire", {
                      date: remainDate,
                    })}
                  </span>
                </div>
              </div>
            )}
            <div className="flex flex-col items-center">
              {currentPackage?.key === "Free" ? null : (
                <>
                  <Button
                    type="primary"
                    className="success transition-all font-medium w-40"
                    onClick={() => {
                      if (!!user.phone_number) setIsModalOpen("extend");
                      else setIsModalOpen("phoneextend");
                    }}
                  >
                    {t("managerPackage.extendBtn")}
                  </Button>
                  <span className="text-center block mt-2">
                    {t("managerPackage.extendTitle", {
                      date: dayjs(user?.subscription?.end_date).format(
                        "DD/MM/YYYY"
                      ),
                    })}
                  </span>
                </>
              )}
              {currentPackage?.key !== "Free" &&
              currentPackage?.key !== "Master" ? (
                <div className="relative w-full text-slate-500 my-3 text-sm before:w-[30%] before:absolute before:-translate-y-1/2 before:top-1/2 before:left-0 before:h-[1px] before:bg-slate-900/30 after:w-[30%] after:absolute after:-translate-y-1/2 after:top-1/2 after:right-0 after:h-[1px] after:bg-slate-900/30">
                  <span className="bg-transparent text-center w-full block">
                    {t("managerPackage.or")}
                  </span>
                </div>
              ) : null}
              {currentPackage?.key === "Master" ? null : (
                <Button
                  type="primary"
                  className="transition-all font-medium w-40"
                  onClick={() => {
                    if (!!user.phone_number) setIsModalOpen("upgrade");
                    else setIsModalOpen("phoneupgrade");
                  }}
                >
                  {t("managerPackage.upgradeBtn")}
                </Button>
              )}
              <span className="text-center block mt-2">
                {t("managerPackage.upgradeTitle")}
              </span>
            </div>
          </div>
        </div>

        <ContactBlock />

        <div className="relative w-full text-slate-500 my-5 text-sm before:w-[30%] before:absolute before:-translate-y-1/2 before:top-1/2 before:left-0 before:h-[1px] before:bg-slate-900/30 after:w-[30%] after:absolute after:-translate-y-1/2 after:top-1/2 after:right-0 after:h-[1px] after:bg-slate-900/30">
          <span className="bg-transparent text-center w-3/4 block"></span>
        </div>

        <div className="flex flex-col items-center pb-10">
          <span className="text-xl sm:text-2xl font-bold text-center">
            {t("servicePackages.otherProducts.title")}
          </span>
          <span className="text-base sm:text-lg text-center">
            {t("servicePackages.otherProducts.subTitle")}
          </span>
        </div>

        <div className="flex gap-2 flex-col lg:flex-row w-full pb-10">
          <div className="flex-1 w-full flex gap-4 bg-gray-100 md:px-6 p-3 md:py-4 rounded-lg lg:rounded-l-none justify-center">
            <img src={ggdrive} alt="ggdrive" className="hidden md:block" />
            <div className="flex flex-col justify-between gap-2">
              <div className="flex gap-2 items-center">
                <img src={ggdrive} alt="ggdrive" className="md:hidden block" />
                <div className="flex flex-col">
                  <span className="text-xl font-semibold">
                    {t("servicePackages.ggdrive.title")}
                  </span>
                  <span className="whitespace-pre-line text-sm">
                    {t("servicePackages.ggdrive.subTitle")}
                  </span>
                </div>
              </div>
              <a
                href="https://store.hoangphucphoto.com/san-pham/tai-khoan-luu-tru-google-drive-vinh-vien-500gb-1tb-2tb-5tb/"
                className="flex gap-2 text-primary justify-center md:justify-normal"
              >
                <ChevronsRight className="w-4" />
                {t("servicePackages.ggdrive.btn")}
              </a>
            </div>
          </div>
          <div className="flex-1 w-full flex gap-4 bg-gray-100 md:px-6 p-3 md:py-4 rounded-lg lg:rounded-r-none justify-center flex-row-reverse lg:flex-row">
            <img
              src={paneldarkroom}
              alt="paneldarkroom"
              className="w-24 hidden md:block"
            />
            <div className="flex flex-col justify-between gap-2 md:items-end lg:items-start">
              <div className="flex gap-2 items-center">
                <img
                  src={paneldarkroom}
                  alt="paneldarkroom"
                  className="w-24 md:hidden block"
                />
                <div className="flex flex-col">
                  <span className="text-xl font-semibold">
                    {t("servicePackages.paneldarkroom.title")}
                  </span>
                  <span className="whitespace-pre-line text-sm">
                    {t("servicePackages.paneldarkroom.subTitle")}
                  </span>
                </div>
              </div>
              <a
                href="https://store.hoangphucphoto.com/darkroom/"
                className="flex gap-2 text-primary justify-center md:justify-normal"
              >
                <ChevronsRight className="w-4" />
                {t("servicePackages.paneldarkroom.btn")}
              </a>
            </div>
          </div>
        </div>
      </div>
      <UpdatePhone
        isVisible={
          isModalOpen === "phoneextend" || isModalOpen === "phoneupgrade"
            ? isModalOpen
            : ""
        }
        onClick={(value) => {
          setIsModalOpen(!!value ? value : undefined);
        }}
      />
      <Modal
        style={{ height: "fit-content" }}
        title={
          <div className="text-primary font-medium text-2xl">
            {t("managerPackage.extendModal.title")}
          </div>
        }
        open={isModalOpen === "extend"}
        onCancel={() => {
          setIsModalOpen(undefined);
        }}
        footer={null}
      >
        <ExtendPackage onClick={(data) => setIsModalOpen({ buy: data })} />
      </Modal>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("managerPackage.extendModal.title")}
          </div>
        }
        open={typeof isModalOpen === "object"}
        onCancel={() => {
          setIsModalOpen(undefined);
        }}
        footer={null}
      >
        <ModalPayment
          values={typeof isModalOpen === "object" ? [isModalOpen?.buy] : []}
        />
      </Modal>
      <Modal
        title={
          <div className="text-primary font-medium text-2xl">
            {t("managerPackage.upgradeModal.title")}
          </div>
        }
        open={isModalOpen === "upgrade"}
        onCancel={() => {
          setIsModalOpen(undefined);
        }}
        footer={null}
      >
        <UpgradePackage onClick={(data) => setIsModalOpen({ buy: data })} />
      </Modal>
    </>
  );
};

export default PackageManagement;
